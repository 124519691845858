import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const FrontPageBanner = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        bgImage: file(relativePath: {eq: "home-bg.jpg"}) {
          childImageSharp {
            fluid(maxHeight: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
    const imageData = data.bgImage.childImageSharp.fluid
    return (
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        {children}
      </BackgroundImage>
      )
    }}
  />
)

const StyledFrontPageBanner = styled(FrontPageBanner)`
  height: 100%;
`;

export default StyledFrontPageBanner