import React from "react"
import SEO from "../components/seo"
import Layout from "../components/NewLayout"
import styled from "styled-components"
import { colors } from "Utilities"
import { Card } from "../Elements/Cards"
import GoogleApiWrapper from "../components/Map/GoogleMap"
import StyledFrontPageBanner from "../components/landingPageBg"

const infoText = {
  textDecoration: "none",
  color: "#222",
  cursor: "pointer"
};

const divider = {
  display: "inline-block",
  width: "250px",
  height: "2px",
  background: "linear-gradient(to right,#000000 0,#000000 50%)",
  marginTop: "20px",
  marginBottom: "20px",
};

const ContactPage = () => (
  
<Layout>
  <SEO title="Home" />
  <Container>
  <StyledFrontPageBanner>
    <TextContainer>
    
    <ParkWrapper>
    <h1>Contact Us.</h1>
    <span style={divider}></span>
    <br/>
    <ContactWrapper>
    
    <StormWrapper>
    <p>ADDRESS</p>
    <p>Storm Hair & Beauty,<br/>171a High Street,<br/> Street,<br/> Somerset,<br/>
    BA16 0NE</p>
    <span style={divider}></span>
    </StormWrapper>
    <PhoneWrapper>
    
    <p>TELEPHONE:</p>
      <a style={infoText} href="tel:01458448080">
        <p >+44 &#40;0&#41;1458 448080</p>
      </a>
    
    <span style={divider}></span>
    <br/>
    <p>EMAIL:</p>
      <a style={infoText} href="mailto:info@stormhairandbeauty.co.uk">
        <p>info@stormhairandbeauty.co.uk</p>
      </a>
    </PhoneWrapper>
    </ContactWrapper>
  </ParkWrapper>
      <ParkWrapper>
          <h1>Where to park.</h1>
          <span style={divider}></span>
          <br/>
          <p>Storm Hair & Beauty is conveniently located close to the centre of Street on the High Street. We benefit from nearby parking in Goswell Road (free for a set period - please check the parking signs for details) and in Cranhill Road car park - pay and display.</p>
        </ParkWrapper>
        
    </TextContainer>
    </StyledFrontPageBanner>
    
    <GoogleWrapper>
      <GoogleApiWrapper/>
    </GoogleWrapper>
       

        
      
  </Container>
  
</Layout>
    

)

export default ContactPage

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 1rem 3rem;
    @media (max-width: 769px) {
      padding: 1rem 0rem;
    }
`

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  grid-gap: 3rem;
  margin: 3rem 3rem;
  @media (max-width: 769px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 2rem 0;
    max-width: 100vw;
  }
`;

const ParkWrapper = styled(Card)`
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  padding: 2rem;
  background-color: ${colors.lightGrey};
  h1 {
    color: ${colors.black};
    font-weight: lighter;
  }
  @media (max-width: 769px) {
    max-width: 100vw;
  }
`
const ContactWrapper = styled.div`
  display: flex;
  
  justify-content: space-around;
  width: 100%;
  @media (max-width: 769px) {
    flex-wrap: wrap;
  }
`
const StormWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  min-width: 100px;
  p {
    min-width: 200px;
    margin: 0;
  }
`;

const PhoneWrapper = styled.div`
padding: 0 1rem;
width: 100%;
p {
  margin: 0;
}
`;

const GoogleWrapper = styled.div`
  margin: 2rem 0;
  @media (max-width: 769px) {
    
    margin: 1rem 1rem;
  }
`;



